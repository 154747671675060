import { GraphqlResources } from '../../types/content'
import { Document } from '@contentful/rich-text-types'
import renderDocumentToReactComponents from '../../modules/contentful/renderDocumentToReactComponents/renderDocumentToReactComponents'
import ContentfulResourceContext from './ContentfulResourcesProvider'

type ContentfulDocumentArgs = {
  content: Document
  resources: GraphqlResources
}

const ContentfulDocument = ({ content, resources }: ContentfulDocumentArgs) => {
  return (
    <ContentfulResourceContext.Provider value={{ ...resources, useSection: true }}>
      <div>{renderDocumentToReactComponents(content)}</div>
    </ContentfulResourceContext.Provider>
  )
}

export default ContentfulDocument
