import React, { useEffect } from 'react'
import { GetStaticProps } from 'next'
import ContentPage, {
  ContentPageProps,
  generateContentPagePropsSSR,
} from '../components/ContentPage/ContentPage'
import { setViewedPage } from '../modules/ecommerce/product-data-layer'

const IndexPage = ({
  page,
  menu,
  footer,
  graphqlResources,
  version,
  slug,
  authenticated,
}: ContentPageProps) => {
  //trigger setViewedPage event on page load
  useEffect(() => {
    setViewedPage(page?.fields?.slug['en-NZ'], page?.sys?.id, version)
  }, [])

  return (
    <ContentPage
      page={page}
      menu={menu}
      footer={footer}
      graphqlResources={graphqlResources}
      slug={slug}
      authenticated={authenticated}
    />
  )
}
export default IndexPage

export const getStaticProps: GetStaticProps<ContentPageProps> = async () => {
  return generateContentPagePropsSSR('/')
}
