import { ApolloClient, HttpLink, InMemoryCache, NormalizedCacheObject } from '@apollo/client'

export async function createApolloServerClient(): Promise<ApolloClient<NormalizedCacheObject>> {
  const link = new HttpLink({
    uri: process.env.NEXT_PUBLIC_GRAPHQL_ENDPOINT, // Server URL (must be absolute)
    credentials: 'omit',
    headers: {
      origin: process.env.NEXT_GRAPHQL_SSR_ORIGIN,
    },
  })

  // Generate client
  return new ApolloClient({
    ssrMode: true,
    link,
    cache: new InMemoryCache(),
  })
}
