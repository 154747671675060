import {
  createContentfulClient,
  getFooter,
  getGraphqlResources,
  getMenu,
  getPageBySlug,
} from '../../lib/contentful'
import { GetStaticPropsResult } from 'next/types'
import { useLocalisedContent } from '../../context/Location/useLocalisedContent'
import { GraphqlResources } from '../../types/content'
import ContentfulDocument from '../ContentfulDocument/ContentfulDocument'
import Footer from '../Footer/Footer'
import Menu from '../Menu/Menu'
import { TypePage, TypeResourceSet } from '../../types/contentful'
import { createApolloServerClient } from '../../lib/apolloServerClient'
import TitleBlock from '../TitleBlock/TitleBlock'
import Button, { contentfulSize, contentfulType, contentfulVariant } from '../Button/Button'
import { Icon } from '../Icon/Icon'
import React, { useEffect } from 'react'
import HeadBlock from '../SeoBlock/HeadBlock'
import { revalidate } from '../../lib/cache'
import { NextApiRequest, NextApiResponse } from 'next'
import { setCookie, getCookie } from 'cookies-next'
import dayjs from 'dayjs'
import Sections from '../Sections/Sections'
import ContentfulResourceContext from '../ContentfulDocument/ContentfulResourcesProvider'
import HeroBlock from '../HeroBlock/HeroBlock'
import classNames from 'classnames'

export type ContentPageProps = {
  page: TypePage<'WITHOUT_UNRESOLVABLE_LINKS', string>
  menu: TypeResourceSet<'WITHOUT_UNRESOLVABLE_LINKS', string>
  footer: TypeResourceSet<'WITHOUT_UNRESOLVABLE_LINKS', string>
  graphqlResources: GraphqlResources
  version?: string
  slug?: string
  authenticated?: boolean
  isCardsPage?: boolean
}

export const ContentPage = ({
  page,
  menu,
  footer,
  graphqlResources,
  slug,
  authenticated,
  isCardsPage,
}: ContentPageProps) => {
  const localizedPage = useLocalisedContent(page)

  const [clientSideAuth, setClientSideAuth] = React.useState<boolean>(false)

  useEffect(() => {
    const authCookie = process.env.NEXT_PUBLIC_COOKIES_DOMAIN
      ? !!getCookie('next-auth-check', {
          domain: process.env.NEXT_PUBLIC_COOKIES_DOMAIN,
        })
      : false // check the cookie on clientside for SSR pages
    setClientSideAuth(authCookie)
  }, [])

  return (
    <>
      <HeadBlock
        title={localizedPage?.fields?.seoTitle || localizedPage?.fields?.title}
        description={localizedPage?.fields?.seoDescription}
        keywords={localizedPage?.fields?.seoKeywords}
        image={localizedPage?.fields?.seoImage?.fields?.image?.fields?.file?.url}
        noIndex={localizedPage?.fields?.seoNoIndex}
      />
      <style jsx global>{`
        #__next {
          background-color: white;
        }
        body {
          background-color: var(--bg-color);
        }
      `}</style>
      <Menu
        menu={menu}
        theme={localizedPage?.fields?.navigationTheme}
        authenticated={authenticated || clientSideAuth}
      />
      {localizedPage?.fields?.showPageTitle === true && (
        <TitleBlock
          title={localizedPage?.fields?.title}
          preTitle={localizedPage?.fields?.preTitle}
          isHero={true}
        >
          <div className={'flex justify-center md:gap-4 flex-wrap md:flex-nowrap'}>
            {localizedPage?.fields?.ctaButtons?.map(button => (
              <Button
                id={button.sys.id}
                key={button.sys.id}
                href={button.fields.url}
                buttonStyle={contentfulType[button.fields.style]}
                size={contentfulSize[button.fields.size]}
                urlBehaviour={button?.fields?.urlBehaviour}
                className={
                  'flex w-[70%] md:w-auto my-2 md:my-0 text-center justify-center items-center'
                }
                variant={contentfulVariant[button?.fields?.variant]}
              >
                {button.fields.icon && <Icon className="mr-1" name={button.fields.icon} />}
                {button.fields.label}
              </Button>
            ))}
          </div>
        </TitleBlock>
      )}
      <main
        className={classNames(
          localizedPage?.fields?.marginType === 'Center'
            ? 'w-[85%] sm:w-[90%] mx-auto md:px-[20%]'
            : 'container mx-auto',
          isCardsPage &&
            '[&>*>div#image]:w-1/2 [&>*>ol>li>div#image]:w-1/2 [&>*>ul>li>div#image]:w-1/2'
        )}
      >
        {localizedPage?.fields?.heroBlock && <HeroBlock entry={localizedPage?.fields?.heroBlock} />}

        {localizedPage?.fields?.sections && (
          <section className="container mx-auto">
            <ContentfulResourceContext.Provider value={graphqlResources}>
              <Sections
                sections={localizedPage.fields.sections}
                navigationTheme={localizedPage?.fields?.navigationTheme}
              />
            </ContentfulResourceContext.Provider>
          </section>
        )}

        <ContentfulDocument content={localizedPage?.fields?.content} resources={graphqlResources} />
      </main>
      <Footer menu={footer} colour={localizedPage?.fields?.footerColour} />
    </>
  )
}

export const generateContentPageProps = async (
  slug: string,
  version?: string,
  authenticated?: boolean
): Promise<GetStaticPropsResult<ContentPageProps>> => {
  const contentfulClient = createContentfulClient()
  const apollo = await createApolloServerClient()

  const [page, menu, footer, graphqlResources] = await Promise.all([
    getPageBySlug(contentfulClient, slug),
    getMenu(contentfulClient),
    getFooter(contentfulClient),
    getGraphqlResources(apollo),
  ])

  if (!page) {
    return {
      notFound: true,
      revalidate,
    }
  }

  return {
    props: {
      page,
      menu,
      footer,
      graphqlResources,
      ...(version && { version }), // Add version only if not null
      slug,
      authenticated,
    },
  }
}

export const generateContentPagePropsSSR = async (
  slug: string,
  version?: string
): Promise<GetStaticPropsResult<ContentPageProps>> => {
  const contentfulClient = createContentfulClient()
  const apollo = await createApolloServerClient()
  const [page, menu, footer, graphqlResources] = await Promise.all([
    getPageBySlug(contentfulClient, slug),
    getMenu(contentfulClient),
    getFooter(contentfulClient),
    getGraphqlResources(apollo),
  ])

  if (!page) {
    return {
      notFound: true,
      revalidate,
    }
  }

  return {
    props: {
      page,
      menu,
      footer,
      graphqlResources,
      ...(version && { version }), // Add version only if not null
      slug,
      ...(page?.fields?.slug?.['en-NZ']?.includes('/cards') && { isCardsPage: true }),
    },
    revalidate,
  }
}

export const generateContentPageWithAB = async (
  enableAB: boolean,
  slug1: string,
  slug2: string,
  req: NextApiRequest,
  res: NextApiResponse
): Promise<GetStaticPropsResult<ContentPageProps>> => {
  const aexABTestCookie = 'aexABTest'
  let version = '1'
  const auth = process.env.NEXT_PUBLIC_COOKIES_DOMAIN
    ? !!getCookie('next-auth-check', {
        req,
        res,
        domain: process.env.NEXT_PUBLIC_COOKIES_DOMAIN,
      })
    : false

  if (enableAB && slug2) {
    version = getCookie(aexABTestCookie, { req, res }) || ''
    if (!version?.length) {
      const expiryDate = dayjs().add(6, 'month').toDate()
      version = (Math.floor(Math.random() * 2) + 1).toString()
      setCookie(aexABTestCookie, version.toString(), { req, res, expires: expiryDate })
    }
  }

  const chosenSlug = version === '2' && slug2 ? slug2 : slug1

  return generateContentPageProps(chosenSlug, version, auth)
}

export default ContentPage
